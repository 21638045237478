* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Encode Sans Expanded", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;700&family=Noto+Sans+JP:wght@500&display=swap");

.App {
    text-align: center;
}

.image {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    border-radius: 0px;
    z-index: 0;
}

.slide {
    opacity: 0;
    /* transition-duration: 1s ease; */
    transition: all 1.5s;
}

.slide.active {
    opacity: 1;
    /* transition: all 0.9s ease-in-out; */
    transition: all 3s ease-in-out;
}
